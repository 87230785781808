.ol-map {
    min-width: 600px;
    height: 100%;
    width: 100%;
}

.ol-full-screen {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
}

.ol-zoom {
    position: absolute;
    top: 0;
    left: 0;
    margin: 10px;
    display: flex;
    flex-direction: column;
}

.ol-zoom button {
    margin-bottom: 5px;
}

.ol-zoom-extent {
    position: absolute;
    top: 85px;
    left: 0;
    margin: 10px;
}

.ol-rotate-reset {
    position: absolute;
    top: 55px;
    left: 0;
    margin: 10px;
}

.ol-attribution {
    display: none;
}
