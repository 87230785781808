body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body {
    height: 100vh;
}

#root {
    height: 100%;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border-radius: 24px;
}

::-webkit-scrollbar-track {
  background: #ECEFF2;
}

::-webkit-scrollbar-thumb {
  background: #A0B1BE;
  border-radius: 24px;
}

::-webkit-scrollbar-thumb:hover {
  background: #88949f;
}